<template>
    <div id="supplier-onboarding-home-page">
        <!-- HEADER -->
        <div v-if="!hasFinished">
            <vx-card class="mt-5 bg-primary mb-5">
                <div class="vx-row">
                    <div v-if="SupplierStatus <= 3" class="vx-col w-full">
                        <h2 class="extrabold mb-2">¡Estamos felices de verte por aquí!</h2>
                        <p class="bold">Completa tu perfil para que aproveches al máximo RedGirasol.</p>
                    </div>
                    <div v-else class="vx-col w-full">
                        <h2 class="extrabold mb-2">¡Actualicemos tu perfil!</h2>
                        <p class="bold">Por favor actualiza la información pendiente en tu perfil.</p>
                    </div>
                </div>
            </vx-card>
    
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div v-if="isMounted">
                        <!-- REDES SOCIALES -->
                        <supplier-onboarding-social-network
                            v-if="(currentOnboardingStep == 'pm_mex_9_id_verification_legal_representative' || currentOnboardingStep == 'pf_mex_7_id_verification' || currentOnboardingStep == 'pm_mex_10_legal_representative_credit_history_report' || currentOnboardingStep == 'pf_mex_8_credit_history_report')"
                            :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
                        <!-- COVERTURA -->
                        <supplier-onboarding-coverage
                            v-if="(currentOnboardingStep == 'pm_mex_11_social_networks' || currentOnboardingStep == 'pf_mex_9_social_networks')"
                            :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
                        <!-- CONTACTOS ADICIONALES -->
                        <supplier-onboarding-contacts
                            v-if="(currentOnboardingStep == 'pm_mex_12_coverage' || currentOnboardingStep == 'pf_mex_10_coverage')"
                            :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
                        <!-- CHECKPOINT -->
                        <supplier-onboarding-second-check-point
                            v-if="(currentOnboardingStep == 'pm_mex_13_main_contacts' || currentOnboardingStep == 'pf_mex_11_main_contacts')"
                            :onboarding-step-data="base" :is-moral="isMoral" @updated="getSupplierData" />
        
                        <!-- WIZARD -->
                        <form-wizard v-if="showWizard" 
                            ref="wizard" 
                            color="black" 
                            errorColor="rgba(var(--vs-danger), 1)" 
                            :title="null"
                            :subtitle="null" 
                            backButtonText="Atrás" 
                            nextButtonText="Continuar" 
                            finishButtonText="Continuar"
                            @on-complete="formSubmitted">
                
                            <!-- TAB DOCUMENTS -->
                                <tab-content
                                    title="Documentos"
                                    class="mb-5"
                                    :before-change="validateDocuments"
                                    >
                                    <vx-card title="Documentos para validación">
                                        <div v-if="isMounted" class="vx-row">
                                            <document-upload
                                                v-for="df in allDocumentFields"
                                                v-if="showDocument(df)"
                                                :key="df.id"
                                                :target_id="df.target_id"
                                                :f="df.formField"
                                                :doc="df.documentSet"
                                                :evaluator="evaluateCondition"
                                                :loadedState="df.loadedState"
                                                :stickToLoadedState="true"
                                                @on-success="onDocumentUploadSuccess"
                                                @on-rejected="onDocumentRejected"
                                                @on-verified="onDocumentVerified"
                                                @on-revoked-verification="onDocumentVerificationRevoked"
                                                :editableByBackoffice="true"
                                            />
                                    </div>
                                    </vx-card>
                                </tab-content>
                
                            <!-- TECNOLOGÍAS -->
                                <tab-content
                                    title="Tecnologías"
                                    class="mb-5"
                                    :before-change="validateTechnology"
                                    >
                                    <accreditations-control 
                                        v-if="isMounted" 
                                        :is_from_onboarding="true" 
                                        :from_profile="false" 
                                        :supplier_id="SupplierId" />
                                </tab-content>
            
                            <!-- Contratos -->
                                <tab-content
                                    title="Contratos"
                                    class="mb-5"
                                    >
                                    <supplier-onboarding-contracts-page
                                        v-if="isMounted"
                                        :supplier_id="SupplierId" 
                                        :isVerified="identityIsCompleted" 
                                        :isPM="isMoral"
                                        :supplier="supplier_data"
                                        @updated="getSupplierData"
                                        />
                                </tab-content>
                
                            <!-- TECNOLOGÍAS -->
                                <!-- <tab-content
                                    title="Solicitud"
                                    class="mb-5"
                                    :before-change="validateTechnology"
                                    >
                                    <vx-card class="p-4">
                                        <h3 class="mb-4">Solicitud de revisión</h3>
                                        <p>
                                            ¡Felicidades! Has completado toda la información necesaria para la apertura de la cuenta.
                                            <br> Envianos un mensaje a nuestro Whatsapp ##### para terminar el proceso con...
                                        </p>
                                    </vx-card>
                                </tab-content> -->
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <vx-card class="mt-5 big-title-bg mb-5">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <h1 class="rg-big-title extrabold">¡Gracias!</h1>
                    </div>
                </div>
            </vx-card>
            <supplier-onboarding-completed />
        </div>
    </div>
</template>

<script>
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import AccreditationsControl from '@supplier/AccreditationsControl.vue';
import ContractsPage from "@views/supplier/contracts/ContractsPage";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import SupplierOnboardingContractsPage from "@views/supplier/onboarding/components/SupplierOnboardingContractsPage.vue"
import SupplierOnboardingCompleted from './components/SupplierOnboardingCompleted.vue';
import SupplierOnboardingSocialNetwork from "@views/supplier/onboarding/components/SupplierOnboardingSocialNetwork.vue";
import SupplierOnboardingCoverage from "@views/supplier/onboarding/components/SupplierOnboardingCoverage.vue";
import SupplierOnboardingContacts from "@views/supplier/onboarding/components/SupplierOnboardingContacts.vue";
import SupplierOnboardingSecondCheckPoint from "@views/supplier/onboarding/components/SupplierOnboardingSecondCheckPoint.vue";

const verifiedEnumValue = "verificado";
const rejectedEnumValue = "rechazado";
const lastStepPM = "pm_mex_20_merchant_contract";
const lastStepPF = "pf_mex_18_merchant_contract";
const requiredObjects = [
  'user.personal',
  'user.personal.phone',
  'user.business.personal',
  'user.business.personal.phone',
  'contracts',
  'user.personal.address',
  'user.personal.fiscalAddress',
  'user.personal.businessAddress',
  'user.personal.bankAccounts',
  'user.personal.tins',
  'user.personal.creditProfile',
  'user.personal.phone',
  'user.business.personal',
  'user.business.address',
  'user.business.tins',
  'user.business.phone',
  'user.business.personal.address',
  'user.business.personal.phone',
  'user.business.fiscalAddress',
  'user.business.bankAccounts.bank',
  'user.business.personal.creditProfile',
  'contacts',
  'tools',
  'customerTypes'
];
export default {
    name: "SupplierOnboardingHomePage",
    mixins: [docsHelper, formHelper, userIdentityHelper],
    components: {
        FormWizard,
        TabContent,
        AccreditationsControl,
        ContractsPage,
        SupplierOnboardingContractsPage,
        SupplierOnboardingCompleted,
        SupplierOnboardingSocialNetwork,
        SupplierOnboardingCoverage,
        SupplierOnboardingContacts,
        SupplierOnboardingSecondCheckPoint
    },
    data: () => ({
        flow_id_pfae: 1,
        flow_id_pm: 2,
        documentsStates: [],
        validatedDocuments: [],
        allDocumentFields: [],
        flow: null, 
        allSecctions: [],
        isMounted: false,
        identityIsCompleted: false,
        base: {},
        status: null,
        technologiesList: []
    }),
    async mounted() {
        if(!this.isAgent) {
            this.showLoading(true);
            await this.getSupplierData();
            await this.getDocuments();
            await this.getCCMStatus();
            this.isMounted = true;
            this.getWizardStep();
    
            this.$store.subscribe(mutation => {
                if(mutation.type == 'SET_MATI_PROCESS_IS_FINISHED'){
                    // Aquí es cuando se firma el contrato de CM del proveedor. 
                    // Actualizo los datos para que si ya cayó el webhook, pues ya esté el nuevo paso
                    this.getUserInfo("supplier");
                    // O podemos actualizar directamente aquí el paso. Preguntar.
                }
            });
            this.showLoading(false);
        }
    },
    computed: {
        isAgent() {
            return this.$acl.check('isAgent');
        },
        isMoral() {
            return this.UserPersonType == 0;
        },
        supplier_data(){
            return [{
                type: 'supplier'
                , name: this.isMoral ? this.getPersonalFullName(this.businessOwner) : this.getPersonalFullName(this.personal)
                , email: this.base.user?.email
                , telephone: this.isMoral ? this.businessOwner.phone : this.personal.phone
            }];
        },
        businessOwner(){
            return this.base.user?.business?.personal;
        },
        business(){
            return this.base.user.business;
        },
        personal(){
            return this.base.user?.personal;
        },
        hasFinished() {
            return (this.SupplierOnboardingStep == lastStepPM || this.SupplierOnboardingStep == lastStepPF )
        },
        currentOnboardingStep() {
            return this.SupplierOnboardingStep;
        },
        showWizard(){
            if(this.isMoral) {
                return this.currentOnboardingStep == "pm_mex_14_business_checkpoint_2"
                || this.currentOnboardingStep == "pm_mex_15_technology_training_certifications"
                || this.currentOnboardingStep == "pm_mex_16_technology_testimonies"
                || this.currentOnboardingStep == "pm_mex_17_technology_questionnaire"
                || this.currentOnboardingStep == "pm_mex_18_technology_checkpoint"
                || this.currentOnboardingStep == "pm_mex_19_record_of_knowledge"
                || this.currentOnboardingStep == "pm_mex_20_merchant_contract"
            } else {
                return this.currentOnboardingStep == "pf_mex_12_business_checkpoint_2"
                || this.currentOnboardingStep == "pf_mex_13_technology_training_certifications"
                || this.currentOnboardingStep == "pf_mex_14_technology_testimonies"
                || this.currentOnboardingStep == "pf_mex_15_technology_questionnaire"
                || this.currentOnboardingStep == "pf_mex_16_technology_checkpoint"
                || this.currentOnboardingStep == "pf_mex_17_record_of_knowledge"
                || this.currentOnboardingStep == "pf_mex_18_merchant_contract"
            }
        }
    },
    methods: {
        getWizardStep(){
            let wiz = this.$refs['wizard'];
            const currentStep = this.SupplierOnboardingStep;

            // documentos
            if (currentStep == "pm_mex_18_technology_checkpoint" || currentStep == "pf_mex_16_technology_checkpoint") {
                // tecnologías
                wiz.changeTab(0, 1);
            } else if(currentStep == "pm_mex_19_record_of_knowledge" || currentStep == "pf_mex_17_record_of_knowledge") {
                // contratos
                wiz.changeTab(1, 2);
            }
        },
        async getSupplierData(){
            try{
                // ?filter[status]=3 // ejemplo de filtro
                let params = "with[]=" + requiredObjects.join("&with[]=");
                let response = await axios.get(`/api/v1/supplier/${this.SupplierId}?${params}`);
                this.base = response.data;
                this.user.supplier_onboarding_step = this.base.onboarding_step;
            }
            catch(e){
                this.warn(e);
                this.failed = true;
            }
        }, 
        getPersonalFullName(personal){
            let name = personal?.first_name;
            if(personal?.second_name !== null){
                name += ` ${personal?.second_name}`;
            }
            name += ` ${personal?.last_name_1}`
            if(personal?.last_name_2 !== null){
                name += ` ${personal?.last_name_2}`
            }

            return name;
        },
        async formSubmitted() {
            return await new Promise(async (resolve, reject) => {
                this.showLoading(true, "Validando contratos...");
                if (this.base.contracts != null) {
                    await this.asyncForEach(this.base.contracts, async(contract) => {
                        if(contract.status != "signed") {
                            reject("");
                            return false;
                        }
                    });
                } else {
                    reject("");
                    return false;
                }
                await this.checkIfCanUpdateSupplierOnboardingStep("all-documents");
                this.showLoading(false);
                resolve(true);
            });
        },
        async validateDocuments() {
            // obtenemos los documentos
            return await new Promise(async (resolve, reject) => {
                this.showLoading(true, "Validando documentos...");
                await this.asyncForEach(this.allDocumentFields, async(document) => {
                    if(this.showDocument(document)) {
                        if(document.loadedState == null || document.loadedState.is_verified == rejectedEnumValue) {
                            this.missingFieldsNotif();
                            reject("");
                        }
                    }
                });
                this.showLoading(false);
                await this.checkIfCanUpdateSupplierOnboardingStep("all-documents");
                resolve(true);
            });
        },
        async validateTechnology() {
            // validar tecnologías
            return await new Promise(async (resolve, reject) => {
                this.showLoading(true, "Validando acreditaciones...");
                await this.getSupplierFamilyGroups();

                if (this.technologiesList.length <= 0) {
                    this.warningNotif("Acreditaciones faltantes", 
                    "Solicita la acreditación de por lo menos una tecnología.");
                    reject("Request at least one tech.");
                }
                this.showLoading(false);
                const nextStep = await this.checkIfCanUpdateSupplierOnboardingStep("tech-accreditations");

                if(nextStep.data == "success") {
                    resolve(true);
                } else {
                    this.warningNotif("Acreditaciones faltantes", 
                    "Solicita la acreditación de por lo menos una tecnología.");
                    reject("No se puede continuar.")
                }
            });
        },
        async saveRequest() {
            return true;
        },
        showDocument(doc) {
            if(doc && doc.documentSet) {
                const docId = doc.documentSet.id;
                if(this.isMoral) {
                    // Para PM
                    if(
                        docId == 1 
                        || docId == 10
                        || docId == 12
                        || docId == 13
                        || docId == 9
                        || docId == 5
                        || docId == 67
                        ) {
                            // console.log(docId);
                            // console.log(doc);
                            return true;
                    }
                } else {
                    if(
                        docId == 1 
                        || docId == 5
                        || docId == 2
                        || docId == 3
                        || docId == 11
                        || docId == 66
                        ) {
                            // console.log(docId);
                            // console.log(doc);
                            return true;
                    }
                }
            }
        },
        async getDocuments() {
            try {
                const flowId = this.isMoral ? this.flow_id_pm : this.flow_id_pfae;
                const sectionResponse = await axios.get(`/api/v1/forms/getFlowSectionFields/${flowId}`);
                const url = this.ApiDomain + `/storagev3/documents/flow/${flowId}`;
                const res = await axios.get(url);
                this.documentsStates = res.data;

                this.flow = sectionResponse.data.flow;
                this.allSections = sectionResponse.data.sections;
                this.allDocumentFields = [];
                let hasLogo = false;

                await this.asyncForEach(this.allSections, async (s) => {
                    s.documentFields = [];
                    await this.asyncForEach(s.fields, async (f) => {
                        // check for documents
                        if (f.doc_id != null) {
                            let ff = { formField: f, documentSet: null, target_id: null };
                            let ss = this.documentsStates.find(sid => sid.id == s.id);
                            if (ss && ss.documents) {
                                let dset = ss.documents.find(d => d.id == f.doc_id);
                                if (dset) {
                                    ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                                    ff.documentSet = dset;
                                }
                            }

                            if(ff.documentSet != null) {
                                s.documentFields.push(ff);
                                // logo?
                                if(f.doc_id == 1) {
                                    if(!hasLogo) {
                                        this.allDocumentFields.push(ff);
                                        hasLogo = true;
                                    }
                                } else {
                                    this.allDocumentFields.push(ff);
                                }
                            }
                        }
                    });

                });

                const resDocStates = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
                if(resDocStates){
                    this.allDocumentFields.forEach(f => {
                        if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
                            this.validatedDocuments.push(f.documentSet.id);
                        }
                    })
                }
            } catch (error) {
                console.log(error)
            }
        },
            /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },

        /* obtener ids para los casos correspondientes */
        docTargetEvaluator(cls, cls_2){
            if(cls == 'user' && cls_2 == 'supplier'){
                return this.UserId;        
            }
            this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
            return null;
        },
        onDocumentUploadSuccess(data) {
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
        },
        onDocumentRejected(data) {
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
        },
        onDocumentVerified(data) {
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
            this.validatedDocuments.push(d.documentSet.id);
            this.allSections.forEach(section => {
                section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = true);
            })
        },
        onDocumentVerificationRevoked(data) {
            let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
            d.loadedState = data;
            this.validatedDocuments = this.validatedDocuments.filter(f => f != d.documentSet.id);
            this.allSections.forEach(section => {
                section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = false);
            })
        },
        async getCCMStatus() {
            this.status = await this.getUserIdentityStatus(this.UserId);
            this.identityIsCompleted = this.verificationIsCompleted(this.status);
        },
        async getSupplierFamilyGroups(){
            try {
                let res = await axios.get(`/api/v1/supplier/${this.SupplierId}?with[]=supplierFamilyGroups.familyGroup`);
                // this.technologiesList = res.data.supplier_family_groups;     
                const dataList = res.data.supplier_family_groups;

                dataList.forEach(d => {
                    if (d.accreditation_status == 1 || d.accreditation_status == 2) {
                        this.technologiesList.push(d);
                    } 
                });
                
            }
            catch (e) {
                this.warn(e);
            }
        },
    }
}
</script>

<style>
.bg-primary {
    background-color: #C9F0C5 !important;
}
</style>